import React from 'react'
import { Field } from 'redux-form'

import { isEmailValid, isRequired, isValidPhotoUploadType } from 'utils/fieldValidation'

import { Button, Form, Section, Typography } from 'components'

import i18n from 'translations'

import { StyledInvitationButtonWrapper } from './ChildContactsFormStyled'

const ChildContactsMainForm = ({
  child,
  formValues,
  hasAccessToChildInParentApp,
  isEditContext,
  isEmailDisabled,
  isInvitationResending,
  onResendParentAppInvitation,
  readOnly,
  userTitles,
}) => (
  <React.Fragment>
    <Section
      title={!isEditContext && i18n.t('module:Children:Child:Contacts:Form:generalInformation')}
    >
      <Form.Row label={i18n.t('module:Children:Child:Contacts:Form:title')}>
        <Form.Row.FlexItem>
          <Field
            component={Form.Select}
            disabled={readOnly}
            name="title"
            options={userTitles}
            placeholder={i18n.t('module:Children:Child:Contacts:Form:title')}
            searchable={false}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label={i18n.t('module:Children:Child:Contacts:Form:firstName')} required>
        <Form.Row.FlexItem>
          <Field
            component={Form.TextField}
            disabled={readOnly}
            name="firstName"
            placeholder={i18n.t('module:Children:Child:Contacts:Form:firstName')}
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label={i18n.t('module:Children:Child:Contacts:Form:surname')} required>
        <Form.Row.FlexItem>
          <Field
            component={Form.TextField}
            disabled={readOnly}
            name="surname"
            placeholder={i18n.t('module:Children:Child:Contacts:Form:surname')}
            validate={isRequired}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label={i18n.t('module:Children:Child:Contacts:Form:occupation')}>
        <Form.Row.FlexItem>
          <Field
            component={Form.TextField}
            disabled={readOnly}
            name="occupation"
            placeholder={i18n.t('module:Children:Child:Contacts:Form:occupation')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label={i18n.t('module:Children:Child:Contacts:Form:photo')}>
        <Form.Row.FlexItem>
          <Field
            component={Form.PhotoUpload}
            disabled={readOnly}
            name="photo"
            placeholder={i18n.t('module:Children:Child:Contacts:Form:photo')}
            validate={isValidPhotoUploadType}
          />
        </Form.Row.FlexItem>
      </Form.Row>
    </Section>
    <Section
      title={i18n.t('module:Children:Child:Contacts:Form:contactInformation')}
    >
      <Form.Row
        label={i18n.t('module:Children:Child:Contacts:Form:email')}
        required={formValues?.hasAccessToChildInParentApp}
      >
        <Form.Row.FlexItem>
          <Field
            component={Form.TextField}
            disabled={readOnly || isEmailDisabled}
            name="email"
            placeholder={i18n.t('module:Children:Child:Contacts:Form:email')}
            type="email"
            validate={formValues?.hasAccessToChildInParentApp ? [isRequired, isEmailValid] : [isEmailValid]}
          />
          {isEmailDisabled && (
            <Typography fontSize="12" margin="5px 0 0">
              {i18n.t('module:Children:Child:Contacts:List:emailNote')}
            </Typography>
          )}
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label={i18n.t('module:Children:Child:Contacts:Form:telephone')}>
        <Form.Row.FlexItem>
          <Field
            component={Form.TextField}
            disabled={readOnly}
            name="telephone"
            placeholder={i18n.t('module:Children:Child:Contacts:Form:telephone')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label={i18n.t('module:Children:Child:Contacts:Form:mobile')}>
        <Form.Row.FlexItem>
          <Field
            component={Form.TextField}
            disabled={readOnly}
            name="mobile"
            placeholder={i18n.t('module:Children:Child:Contacts:Form:mobile')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label={i18n.t('module:Children:Child:Contacts:Form:workNumber')}>
        <Form.Row.FlexItem>
          <Field
            component={Form.TextField}
            disabled={readOnly}
            name="workTelephone"
            placeholder={i18n.t('module:Children:Child:Contacts:Form:workNumber')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
    </Section>
    <Section
      title={i18n.t('module:Children:Child:Contacts:Form:address')}
    >
      <Form.Row label={i18n.t('module:Children:Child:Contacts:Form:address')}>
        <Form.Row.FlexItem>
          <Field
            component={Form.TextAreaField}
            disabled={readOnly}
            name="address"
            placeholder={i18n.t('module:Children:Child:Contacts:Form:address')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label={i18n.t('module:Children:Child:Contacts:Form:postcode')}>
        <Form.Row.FlexItem>
          <Field
            component={Form.TextField}
            disabled={readOnly}
            name="postCode"
            placeholder={i18n.t('module:Children:Child:Contacts:Form:postcode')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
    </Section>
    <Section
      title={`${i18n.t('module:Children:Child:Contacts:Form:specificInformationFor')} ${child?.firstName}`}
    >
      <Form.Row label={i18n.t('module:Children:Child:Contacts:Form:relation')}>
        <Form.Row.FlexItem>
          <Field
            component={Form.InfiniteDropdowns.Relations}
            disabled={readOnly}
            name="relation"
            placeholder={i18n.t('module:Children:Child:Contacts:Form:relation')}
            creatable
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label={i18n.t('module:Children:Child:Contacts:Form:parentalResponsibility')}>
        <Form.Row.FlexItem>
          <Field
            component={Form.Switch}
            disabled={readOnly}
            name="hasParentalResponsibility"
            placeholder={i18n.t('module:Children:Child:Contacts:Form:parentalResponsibility')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label={i18n.t('module:Children:Child:Contacts:Form:passwordForCollection')}>
        <Form.Row.FlexItem>
          <Field
            component={Form.TextField}
            disabled={readOnly}
            name="collectionPassword"
            placeholder={i18n.t('module:Children:Child:Contacts:Form:passwordForCollection')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
    </Section>
    <Section
      title={i18n.t('module:Children:Child:Contacts:Form:permissions')}
    >
      <Form.Row label={i18n.t('module:Children:Child:Contacts:Form:accessToParentApp')}>
        <Field
          component={Form.Switch}
          disabled={readOnly}
          name="hasAccessToChildInParentApp"
          placeholder={i18n.t('module:Children:Child:Contacts:Form:accessToParentApp')}
        />
        {hasAccessToChildInParentApp && (
          <StyledInvitationButtonWrapper>
            <Button
              disabled={readOnly || isInvitationResending}
              hierarchy="secondary"
              isLoading={isInvitationResending}
              label={i18n.t('module:Children:Child:Contacts:Form:resendInvitationButton')}
              size="small"
              negativeMargins
              onClick={onResendParentAppInvitation}
            />
          </StyledInvitationButtonWrapper>
        )}
      </Form.Row>
      <Form.Row label={i18n.t('module:Children:Child:Contacts:Form:receivesNewsPostsViaEmail')}>
        <Form.Row.FlexItem>
          <Field
            component={Form.Switch}
            disabled={readOnly}
            name="shouldReceiveNewsletterEmail"
            placeholder={i18n.t('module:Children:Child:Contacts:Form:receivesNewsPostsViaEmail')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
      <Form.Row label={i18n.t('module:Children:Child:Contacts:Form:receivesDiaryViaEmail')}>
        <Form.Row.FlexItem>
          <Field
            component={Form.Switch}
            disabled={readOnly}
            name="shouldReceiveDailyDiaryEmail"
            placeholder={i18n.t('module:Children:Child:Contacts:Form:receivesDiaryViaEmail')}
          />
        </Form.Row.FlexItem>
      </Form.Row>
    </Section>
  </React.Fragment>
)

export default ChildContactsMainForm
