import moment from 'moment'
import _ from 'lodash'

import React, { useMemo } from 'react'
import { Field, FieldArray, InjectedFormProps, reduxForm } from 'redux-form'

import {
  FUNDING_ALLOCATION_OPTIONS,
  FUNDING_INVOICE_DEDUCTION_OPTIONS_V3,
  FUNDING_LINE_ITEM_OPTIONS_ALLOCATE_FUNDING_V3,
  FUNDING_LINE_ITEM_OPTIONS_SUBTRACT_FUNDING_V3,
  FUNDING_LOOP_USAGE_OPTIONS,
  FUNDING_REDISTRIBUTION_RULES_OPTIONS,
  FUNDING_REDISTRIBUTION_RULES_OPTIONS_WITHOUT_APPLY,
  FUNDING_TYPE_OPTIONS,
  FundingAllocationValues,
  FundingPeriodValues,
  FundingRedistributionRulesValues,
  FundingTypeValues,
  NurseryFundingProduct,
} from 'services/product/nurseryFundingV3/constants'
import { NEUTRAL_COLOURS } from 'constants/colors'
import { ModalType } from 'modals'

import {
  isNumberGreaterOrEqualThan,
  isNumberLessOrEqualThan,
  isRequired,
  isValidInteger,
  isValidNumber,
} from 'utils/fieldValidation'
import { createTypeFromEnumValues } from 'utils/typescript'

import {
  Banner,
  Box,
  Button,
  Field as FieldComponent,
  FooterActions,
  Form,
  Section,
  Space,
  Typography,
} from 'components'
import SubdomainCurrencyProvider from 'providers/SubdomainCurrencyProvider'

import i18n from 'translations'

import {
  StyledPriceChangesItem,
  StyledPriceChangesWrapper,
  StyledRemoveButtonWrapper,
  StyledSelectWrapper,
} from './FundingAddFormStyled'
import FundingLineItemLabel from './FundingLineItemLabel'

export const FUNDING_ADD_FORM = 'FundingAddForm'

const isNumberLessOrEqualThan52 = isNumberLessOrEqualThan(52)
const isNumberGreaterOrEqualThan01 = isNumberGreaterOrEqualThan(0.1)
const isValidNumberToOneDecimal = isValidNumber(1)
const isNumberGreaterOrEqualThan1 = isNumberGreaterOrEqualThan(1)

interface FundingAddFormProps {
  formValues?: FundingAddFormValues
  isEdit?: boolean
  isSubmitting?: boolean
  onCancelClick: () => void
  onChangeDeficitCharged: (value: boolean) => void
  onChangeStartDate: (value: moment.Moment, index: number) => void
  onConfirmChangeRedistributionRule: (fieldName: string) => () => void
  onPreviewClick: (modalType: ModalType.PREVIEW_INVOICE_DEDUCTION | ModalType.PREVIEW_LINE_ITEM) => void
  onRemovePriceChangeRow: (index: number) => void
  onSubmit: (values: any) => void
}

type FundingAddFormFullProps = InjectedFormProps<{}, FundingAddFormProps> & FundingAddFormProps

export interface FundingAddFormValues extends NurseryFundingProduct {
  deductFromParentInvoice?: boolean
  fundingAllocation?: createTypeFromEnumValues<FundingAllocationValues>
  fundingPeriod?: createTypeFromEnumValues<FundingPeriodValues>
  fundingType?: createTypeFromEnumValues<FundingTypeValues>
}

const renderPriceChangeRow = ({
  fields,
  i,
  isSubmitting,
  item,
  onChangeStartDate,
  onRemovePriceChangeRow,
}) => (
  <StyledPriceChangesItem $fieldsLength={fields.length} key={i}>
    <Form.Row
      label={0 === i && i18n.t('module:Management:Finance:Funding:Add:hourlyRate')}
      margin="0 0 5px 0"
      width={{ field: '100%' }}
      verticalLabel
    >
      <SubdomainCurrencyProvider>
        {({ currencySymbol }) => (
          <Field
            component={Form.TextField}
            disabled={isSubmitting}
            name={`${item}.prices[0].price`}
            placeholder={i18n.t('module:Management:Finance:Funding:Add:hourlyRate')}
            prefix={currencySymbol}
            prefixWidth="30px"
            type="number"
            validate={[isRequired, isNumberGreaterOrEqualThan1]}
          />
        )}
      </SubdomainCurrencyProvider>
    </Form.Row>
    {1 < fields.length && (
      <React.Fragment>
        <Form.Row
          label={0 === i && _.upperFirst(i18n.t('global:startDate'))}
          margin="0 0 5px 0"
          width={{ field: '100%' }}
          verticalLabel
        >
          <Field
            component={Form.DatePicker}
            disabled={isSubmitting || 0 === i}
            name={`${item}.startDate`}
            placeholder={0 === i ? '-' : _.upperFirst(i18n.t('global:startDate'))}
            validate={1 < fields.length && 0 !== i && isRequired}
            onChange={(value) => onChangeStartDate(value, i)}
          />
        </Form.Row>
        <Form.Row
          label={0 === i && _.upperFirst(i18n.t('global:endDate'))}
          margin="0 0 5px 0"
          width={{ field: '100%' }}
          verticalLabel
        >
          <Field
            component={Form.DatePicker}
            name={`${item}.endDate`}
            placeholder={
              fields.length - 1 === i
                ? i18n.t('module:Management:Finance:Funding:Add:ongoing')
                : _.upperFirst(i18n.t('global:endDate'))
            }
            disabled
          />
        </Form.Row>
      </React.Fragment>
    )}
    {1 < fields.length && (
      <StyledRemoveButtonWrapper $first={0 === i}>
        <Form.RemoveRowButton
          disabled={isSubmitting}
          trash
          onClick={() => {
            fields.remove(i)
            onRemovePriceChangeRow(i)
          }}
        />
      </StyledRemoveButtonWrapper>
    )}
  </StyledPriceChangesItem>
)

const renderPriceChanges = ({ fields, isSubmitting, onChangeStartDate, onRemovePriceChangeRow }) => (
  <React.Fragment>
    {fields.map((item, i) => renderPriceChangeRow({
      fields,
      i,
      isSubmitting,
      item,
      onChangeStartDate,
      onRemovePriceChangeRow,
    }))}
    <Button.ActionButton
      label={i18n.t('module:Management:Finance:Funding:Add:scheduleHourlyRateChange')}
      onClick={() => fields.push({ prices: [{ price: 0 }] })}
    />
  </React.Fragment>
)

const FundingAddForm: React.FC<FundingAddFormFullProps> = ({
  formValues,
  handleSubmit,
  isEdit,
  isSubmitting,
  onCancelClick,
  onChangeDeficitCharged,
  onChangeStartDate,
  onConfirmChangeRedistributionRule,
  onPreviewClick,
  onRemovePriceChangeRow,
  onSubmit,
}) => {
  const priceChanges = formValues?.priceChanges

  const displayAnnualizedBanner = useMemo(() => (_.some(priceChanges,
    ({ startDate }) => startDate && '01' !== moment(startDate).format('DD'))
  ), [priceChanges])

  const renderFooter = () => (
    <FooterActions spaceBetween>
      <FooterActions.Group>
        <FooterActions.Item>
          <Button
            disabled={isSubmitting}
            hierarchy="tertiary"
            label={i18n.t('global:Cancel')}
            onClick={onCancelClick}
          />
        </FooterActions.Item>
      </FooterActions.Group>
      <FooterActions.Group>
        <FooterActions.Item>
          <Button
            isLoading={isSubmitting}
            label={i18n.t('global:Save')}
            submit
          />
        </FooterActions.Item>
      </FooterActions.Group>
    </FooterActions>
  )

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Row
        label={i18n.t('module:Management:Finance:Funding:Add:fundingTypeName')}
        width={{ field: '300px' }}
        verticalLabel
      >
        <Field
          component={Form.TextField}
          disabled={isSubmitting}
          name="name"
          placeholder={i18n.t('module:Management:Finance:Funding:Add:fundingTypeName')}
          validate={isRequired}
        />
      </Form.Row>
      <Form.Row
        label={i18n.t('module:Management:Finance:Funding:Add:fundingType')}
        margin={formValues?.fundingType === FundingTypeValues.REGULAR && '0'}
        verticalLabel

      >
        <Field
          component={Form.RadioGroup}
          disabled={isEdit || isSubmitting}
          name="fundingType"
          options={FUNDING_TYPE_OPTIONS}
          validate={isRequired}
          buttonStyle
          horizontal
        />
      </Form.Row>
      {formValues?.fundingType === FundingTypeValues.REGULAR && (
        <React.Fragment>
          <Form.Row
            label={i18n.t('module:Management:Finance:Funding:Add:fundingPeriod')}
            margin="0"
            verticalLabel
          >
            <Box marginBottom={1} white>
              <Banner.Info>
                {i18n.t('module:Management:Finance:Funding:Add:fundingPeriodInfoText')}
              </Banner.Info>
              <Field
                component={Form.RadioGroup}
                disabled={isSubmitting}
                name="settings.fundingPeriod"
                options={FUNDING_LOOP_USAGE_OPTIONS}
                validate={isRequired}
                buttonStyle
                horizontal
              />
            </Box>
          </Form.Row>
          {formValues?.settings?.fundingPeriod === FundingPeriodValues.STRETCHED && (
            <Form.Row
              label={i18n.t('module:Management:Finance:Funding:Add:stretchedWeeks')}
              width={{ field: '100px' }}
              verticalLabel
            >
              <Field
                component={Form.TextField}
                disabled={isSubmitting}
                name="settings.stretchedWeeks"
                type="number"
                validate={[
                  isRequired,
                  isNumberGreaterOrEqualThan01,
                  isNumberLessOrEqualThan52,
                  isValidNumberToOneDecimal,
                ]}
              />
            </Form.Row>
          )}
          <Form.Row
            label={i18n.t('module:Management:Finance:Funding:Add:fundingAllocation')}
            verticalLabel
          >
            <Field
              component={Form.RadioGroup}
              disabled={isEdit || isSubmitting}
              name="fundingAllocation"
              options={FUNDING_ALLOCATION_OPTIONS}
              validate={isRequired}
            />
          </Form.Row>
        </React.Fragment>
      )}
      {
        formValues?.fundingType === FundingTypeValues.REGULAR
        && formValues?.fundingAllocation === FundingAllocationValues.ALLOCATE_TO_SESSIONS
        && (
          <React.Fragment>
            <Form.Row
              label={i18n.t('module:Management:Finance:Funding:Add:chargeFundingDeficit')}
              verticalLabel
            >
              <Field
                component={Form.Switch}
                disabled={isEdit || isSubmitting}
                name="settings.deficitCharged"
                // @ts-ignore
                onChange={onChangeDeficitCharged}
              />
            </Form.Row>
            <Form.Row required={formValues?.settings?.deficitCharged} width={{ field: '360px' }} verticalLabel>
              <Field
                component={Form.TextField}
                disabled={!formValues?.settings?.deficitCharged || isEdit || isSubmitting}
                name="settings.deficitLineItemName"
                placeholder={i18n.t('module:Management:Finance:Funding:Add:chargeFundingDeficitName')}
                validate={formValues?.settings?.deficitCharged ? [isRequired] : []}
              />
            </Form.Row>
          </React.Fragment>
        )
      }
      {formValues?.fundingType === FundingTypeValues.ONE_OFF && (
        <Form.Row
          label={i18n.t('module:Management:Finance:Funding:Add:deductFromParentInvoice')}
          verticalLabel
        >
          <Field
            component={Form.Switch}
            disabled={isEdit || isSubmitting}
            name="deductFromParentInvoice"
          />
        </Form.Row>
      )}
      {formValues?.fundingType === FundingTypeValues.REGULAR && (
        <Form.Row
          label={i18n.t('module:Management:Finance:Funding:Add:fundedHoursPerWeek')}
          verticalLabel
        >
          <Form.Row.FlexItem flex="0 0 80px">
            <Field
              component={Form.TextField}
              disabled={isSubmitting}
              name="settings.hoursPerWeek"
              placeholder="0"
              type="number"
              validate={isValidInteger}
            />
          </Form.Row.FlexItem>
          <Form.Row.TextItem text="hours" />
          <Form.Row.FlexItem flex="0 0 80px">
            <Field
              component={Form.TextField}
              disabled={isSubmitting}
              name="settings.minutesPerWeek"
              type="number"
              validate={isValidInteger}
            />
          </Form.Row.FlexItem>
          <Form.Row.TextItem text="minutes" />
        </Form.Row>
      )}
      {formValues?.fundingType === FundingTypeValues.REGULAR && (
        <Form.Row
          label={i18n.t('module:Management:Finance:Funding:Add:maxHoursPerDay')}
          verticalLabel
        >
          <Form.Row.FlexItem flex="0 0 80px">
            <Field
              component={Form.TextField}
              disabled={isSubmitting}
              name="settings.maxHours"
              placeholder="0"
              type="number"
              validate={isValidInteger}
            />
          </Form.Row.FlexItem>
          <Form.Row.TextItem text="hours" />
          <Form.Row.FlexItem flex="0 0 80px">
            <Field
              component={Form.TextField}
              disabled={isSubmitting}
              name="settings.maxMinutes"
              type="number"
              validate={isValidInteger}
            />
          </Form.Row.FlexItem>
          <Form.Row.TextItem text="minutes" />
        </Form.Row>
      )}
      {formValues?.fundingType === FundingTypeValues.REGULAR && (
        <React.Fragment>
          <Typography fontSize={18} margin="40px 0 10px 0" bold>
            {i18n.t('module:Management:Finance:Funding:Add:localAuthorityHourlyRate')}
          </Typography>
          <StyledPriceChangesWrapper>
            <FieldArray
              component={renderPriceChanges}
              name="priceChanges"
              props={{ isSubmitting, onChangeStartDate, onRemovePriceChangeRow }}
            />
          </StyledPriceChangesWrapper>
        </React.Fragment>
      )}
      {displayAnnualizedBanner && (
        <Banner.Info>
          {i18n.t('module:Management:Finance:Funding:Add:annualizedOrPackageInvoiceBanner')}
        </Banner.Info>
      )}
      {1 < priceChanges?.length && (
        <Banner.Info>
          {i18n.t('module:Management:Finance:Funding:Add:customLocalAuthorityBanner')}
        </Banner.Info>
      )}
      {
        formValues?.fundingType === FundingTypeValues.REGULAR
        && formValues?.fundingAllocation === FundingAllocationValues.ALLOCATE_TO_SESSIONS
        && (
          <React.Fragment>
            <Typography fontSize={18} margin="35px 0 10px 0" bold>
              {i18n.t('module:Management:Finance:Funding:Add:redistributionRules')}
            </Typography>
            <Typography color={NEUTRAL_COLOURS.GRAY} fontSize={14}>
              {i18n.t('module:Management:Finance:Funding:Add:redistributionRules2')}
            </Typography>
            <Space space="20px" />
            <StyledSelectWrapper>
              <Form.Row
                label={i18n.t('module:Management:Finance:Funding:Add:closureDaysSessionChargeable')}
                margin="0 0 5px 0"
                width={{ field: '240px', label: '240px' }}
              >
                <Field
                  component={Form.Select}
                  disabled={isSubmitting}
                  name="settings.closureDaysSessionChargeable"
                  options={FUNDING_REDISTRIBUTION_RULES_OPTIONS}
                  v2
                  onChange={onConfirmChangeRedistributionRule('closureDaysSessionChargeable')}
                />
              </Form.Row>
              {FundingRedistributionRulesValues.APPLY === formValues.settings.closureDaysSessionChargeable && (
                <Banner.Info>
                  {i18n.t('module:Management:Finance:Funding:Add:closureDaysSessionChargeableApplyHint')}
                </Banner.Info>
              )}
              <Form.Row
                label={i18n.t('module:Management:Finance:Funding:Add:closureDaysSessionNotChargeable')}
                margin="0 0 5px 0"
                width={{ field: '240px', label: '240px' }}
              >
                <Field
                  component={Form.Select}
                  disabled={isSubmitting}
                  name="settings.closureDaysSessionNotChargeable"
                  options={FUNDING_REDISTRIBUTION_RULES_OPTIONS}
                  v2
                  onChange={onConfirmChangeRedistributionRule('closureDaysSessionNotChargeable')}
                />
              </Form.Row>
              {FundingRedistributionRulesValues.APPLY === formValues.settings.closureDaysSessionNotChargeable && (
                <Banner.Info>
                  {i18n.t('module:Management:Finance:Funding:Add:closureDaysSessionNotChargeableApplyHint')}
                </Banner.Info>
              )}
              <Form.Row
                label={i18n.t('module:Management:Finance:Funding:Add:termTimeHolidays')}
                margin="0 0 5px 0"
                width={{ field: '240px', label: '240px' }}
              >
                <Field
                  component={Form.Select}
                  disabled={isSubmitting}
                  name="settings.termTimeHolidays"
                  options={FUNDING_REDISTRIBUTION_RULES_OPTIONS}
                  v2
                  onChange={onConfirmChangeRedistributionRule('termTimeHolidays')}
                />
              </Form.Row>
              {FundingRedistributionRulesValues.APPLY === formValues.settings.termTimeHolidays && (
                <Banner.Info>
                  {i18n.t('module:Management:Finance:Funding:Add:termTimeHolidaysApplyHint')}
                </Banner.Info>
              )}
              <Form.Row
                label={i18n.t('module:Management:Finance:Funding:Add:bookingPlanEndsMidWeek')}
                margin="0 0 5px 0"
                width={{ field: '240px', label: '240px' }}
              >
                <Field
                  component={Form.Select}
                  disabled={isSubmitting}
                  name="settings.bookingPlanEndsMidWeek"
                  options={FUNDING_REDISTRIBUTION_RULES_OPTIONS_WITHOUT_APPLY}
                  v2
                  onChange={onConfirmChangeRedistributionRule('bookingPlanEndsMidWeek')}
                />
              </Form.Row>
            </StyledSelectWrapper>
          </React.Fragment>
        )
      }
      {formValues?.fundingType === FundingTypeValues.ONE_OFF && (
        <Form.Row
          label={i18n.t('module:Management:Finance:Funding:Add:fundingValue')}
          width={{ field: '300px' }}
          verticalLabel
        >
          <SubdomainCurrencyProvider>
            {({ currencySymbol }) => (
              <Field
                component={Form.TextField}
                disabled={isSubmitting}
                name="settings.total"
                placeholder={i18n.t('module:Management:Finance:Funding:Add:fundingValue')}
                prefix={currencySymbol}
                prefixWidth="30px"
                type="number"
                validate={[isRequired, isNumberGreaterOrEqualThan1]}
              />
            )}
          </SubdomainCurrencyProvider>
        </Form.Row>
      )}
      {formValues?.fundingType === FundingTypeValues.ONE_OFF && !formValues?.deductFromParentInvoice && (
        <Form.Row>
          <Field
            component={Form.Checkbox}
            disabled={isSubmitting}
            label={i18n.t('module:Management:Finance:Funding:Add:showItemOnInvoice')}
            name="settings.showItemOnInvoice"
          />
        </Form.Row>
      )}
      {formValues?.fundingType === FundingTypeValues.REGULAR && (
        <Section title={i18n.t('module:Management:Finance:Funding:Add:InvoiceDisplayOptions:title')}>
          {formValues?.fundingAllocation === FundingAllocationValues.ALLOCATE_TO_SESSIONS && (
            <React.Fragment>
              <Form.Row>
                <FieldComponent.InlineEdit
                  editableComponent={(
                    <Field
                      component={Form.RadioGroup}
                      name="settings.invoiceLineItemDeduction"
                      options={FUNDING_INVOICE_DEDUCTION_OPTIONS_V3}
                      validate={isRequired}
                    />
                  )}
                  label={(
                    <FundingLineItemLabel
                      label={i18n.t(
                        'module:Management:Finance:Funding:Add:InvoiceDisplayOptions:invoiceLineItemDeductionLabel',
                      )}
                      onPreviewClick={() => onPreviewClick(ModalType.PREVIEW_INVOICE_DEDUCTION)}
                    />
                  )}
                  isEditMode
                />
              </Form.Row>
              <Form.Divider />
            </React.Fragment>
          )}
          <Form.Row>
            <FieldComponent.InlineEdit
              editableComponent={(
                <Field
                  component={Form.RadioGroup}
                  name="settings.invoiceLineItemDisplay"
                  options={formValues?.fundingAllocation === FundingAllocationValues.ALLOCATE_TO_SESSIONS
                    ? FUNDING_LINE_ITEM_OPTIONS_ALLOCATE_FUNDING_V3
                    : FUNDING_LINE_ITEM_OPTIONS_SUBTRACT_FUNDING_V3}
                  validate={isRequired}
                />
              )}
              label={(
                <FundingLineItemLabel
                  label={i18n.t(
                    'module:Management:Finance:Funding:Add:InvoiceDisplayOptions:invoiceLineItemDisplayLabel',
                  )}
                  onPreviewClick={() => onPreviewClick(ModalType.PREVIEW_LINE_ITEM)}
                />
              )}
              isEditMode
            />
          </Form.Row>
        </Section>
      )}
      {renderFooter()}
    </Form>
  )
}

export default reduxForm<{}, FundingAddFormProps>({ form: FUNDING_ADD_FORM })(FundingAddForm)
