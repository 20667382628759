import _ from 'lodash'

import {
  AUTO_CALCULATION_ITEM_TYPE_V3,
  LINE_ITEM_OPTIONS_V3,
  LINE_ITEM_TYPES,
  REGULAR_LINE_ITEM_OPTIONS_V3,
} from 'services/legacy/invoices/constants'

import { AddInvoiceItemModalV3FormValues } from './components/AddInvoiceItemModalV3Form'

const getDeposit = (deposit) => {
  if (!deposit) {
    return undefined
  }

  return {
    amount: deposit.amount,
    label: deposit.description,
    value: deposit.id,
  }
}

const createCustomOption = (invoiceItem) => ({
  label: invoiceItem.name,
  type: invoiceItem.childProduct.type,
  value: invoiceItem.childProduct.id,
})

export const getInitialValues = (invoicePreview, itemIndex): AddInvoiceItemModalV3FormValues => {
  if (!itemIndex) {
    return {
      type: LINE_ITEM_OPTIONS_V3[0],
    }
  }

  const { invoice } = invoicePreview
  const { items } = invoice
  const invoiceItem = _.find(items, ({ id, uid }) => {
    const key = id?.toString() || uid

    return key === itemIndex.toString()
  })

  const allItems = [...LINE_ITEM_OPTIONS_V3, ...REGULAR_LINE_ITEM_OPTIONS_V3]

  return {
    ...invoiceItem,
    customExtraItemChildProduct: [
      LINE_ITEM_TYPES.EXTRA_ITEM_CHILD_PRODUCT,
      LINE_ITEM_TYPES.EXTRA_ITEM,
      AUTO_CALCULATION_ITEM_TYPE_V3.EXTRA_ITEM_CHILD_PRODUCT,
    ].includes(invoiceItem.type)
      ? createCustomOption(invoiceItem)
      : undefined,
    customExtraSessionChildProduct: [
      LINE_ITEM_TYPES.EXTRA_SESSION_CHILD_PRODUCT,
      AUTO_CALCULATION_ITEM_TYPE_V3.EXTRA_SESSION_CHILD_PRODUCT,
    ].includes(invoiceItem.type)
      ? createCustomOption(invoiceItem)
      : undefined,
    customOneOffDiscountChildProduct: [
      LINE_ITEM_TYPES.ONE_OFF_DISCOUNT,
      AUTO_CALCULATION_ITEM_TYPE_V3.ONE_OFF_DISCOUNT_CHILD_PRODUCT,
    ].includes(invoiceItem.type)
      ? createCustomOption(invoiceItem)
      : undefined,
    customOneOffFundingChildProduct: [
      LINE_ITEM_TYPES.ONE_OFF_FUNDING,
      AUTO_CALCULATION_ITEM_TYPE_V3.ONE_OFF_FUNDING_CHILD_PRODUCT,
    ].includes(invoiceItem.type)
      ? createCustomOption(invoiceItem)
      : undefined,
    deposit: getDeposit(invoiceItem.deposit),
    total: Math.round(Math.abs(+invoiceItem.total) * 100) / 100,
    type: _.find(allItems, ({ nonCustomType, value }) => (
      value === invoiceItem.type || (nonCustomType && nonCustomType.includes(invoiceItem.type))
    )),
    unitPrice: invoiceItem.unitPrice ? Math.round(Math.abs(+invoiceItem.unitPrice) * 100) / 100 : undefined,
  }
}
