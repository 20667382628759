import _ from 'lodash'

import React, { useRef } from 'react'
import { compose } from 'recompose'
import { ConnectedProps, connect } from 'react-redux'

import { CHILD_FUNDING_FILTERS, CHILD_FUNDING_FILTER_OPTIONS } from 'services/legacy/childFunding/constants'
import { CHILD_AGES_OPTIONS } from 'services/legacy/child/constants'
import { NurseryFundingProductType } from 'services/product/nurseryFundingV3/constants'
import { ModalType } from 'modals'

import auth from 'utils/auth'

import { withAppService, withAppServiceProps } from 'services/app'
import { withModalService, withModalServiceProps } from 'services/utils/modal'
import { withChildFundingsService, withChildFundingsServiceProps } from 'services/booking/childFundings'
import { withPaginationUtils, withPaginationUtilsProps } from 'services/utils/pagination'
import { withSecurityService, withSecurityServiceProps } from 'services/security'
import { withRouter, withRouterProps } from 'services/router'
import { withRouteUtilsProps } from 'services/utils/router'

import { useBasicReport } from 'hooks/reports'

import { DatePicker, Form, InfiniteDropdowns, ReportView, Select, Toolbar } from 'components'

import i18n from 'translations'

import { SORT_ORDER } from 'constants/global'
import { getColumns, getStatistics, getTableData } from './FundingV3Helper'
import { FUNDING_ALLOCATION_OPTIONS } from './constants'

type FundingContainerProps = withAppServiceProps
  & withChildFundingsServiceProps
  & withSecurityServiceProps
  & withRouteUtilsProps
  & withRouterProps
  & withPaginationUtilsProps
  & withModalServiceProps

const mapState = (state, {
  appSelectors,
  childFundingsListState,
  childFundingsSelectors,
}: FundingContainerProps) => ({
  childrenData: childFundingsSelectors.getChildFundingsListDataState(state),
  childrenTotalResults: appSelectors.getTotalResults(childFundingsListState),
  errorMessages: appSelectors.getErrorMessages(childFundingsListState),
  isFetching: appSelectors.getIsFetching(childFundingsListState),
  isFinanceV3Enabled: auth.SELECTORS.getIsFinanceV3Enabled(state),
  nurseriesData: childFundingsSelectors.getChildFundingsListDataState(state),
  nurseriesTotalResults: appSelectors.getTotalResults(childFundingsSelectors),
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

const FUNDING_REPORT_GROUPS = {
  read: [
    'childFundingProductListResult.child',
    'child',
  ],
}

const FundingV3Container: React.FC<FundingContainerProps & PropsFromRedux> = ({
  childFundingsActions,
  childFundingsSelectors,
  childrenData,
  childrenTotalResults,
  errorMessages,
  isFetching,
  isFinanceV3Enabled,
  location,
  modalActions,
  nurseriesData,
  nurseriesTotalResults,
  paginationUtils,
  params,
  setLocationQuery,
}) => {
  const isChildContextRef = useRef(!!params?.childId)

  const {
    age,
    allocation,
    child,
    dateRange,
    fundingTypes,
    onAgeChange,
    onAllocationChange,
    onChildChange,
    onDateChange,
    onFundingTypesChange,
    onPageChange,
    onRoomChange,
    onStatusChange,
    page,
    pageCount,
    perPage,
    room,
    status,
    tableColumns,
    tableData,
    totalResults,
  } = useBasicReport({
    actions: {
      clearChildren: childFundingsActions.clearList,
      clearNurseries: childFundingsActions.clearList,
      getChildren: childFundingsActions.list,
      getNurseries: childFundingsActions.list,
    },
    ageOptions: CHILD_AGES_OPTIONS,
    allocationOptions: FUNDING_ALLOCATION_OPTIONS,
    callStatisticsAction: true,
    childPolyfill: true,
    childrenApiGroups: FUNDING_REPORT_GROUPS,
    childrenData,
    childrenOrder: {
      sortField: 'firstName',
      sortOrder: SORT_ORDER.ASC,
    },
    childrenTotalResults,
    defaultFilters: {
      child: params?.childId ? { value: params.childId } : undefined,
      date: {
        after: undefined,
        before: undefined,
      },
      status: _.find(CHILD_FUNDING_FILTER_OPTIONS, { value: CHILD_FUNDING_FILTERS.ACTIVE }),
    },
    errorMessages,
    getColumns: getColumns(isChildContextRef.current),
    getStatistics,
    getTableData,
    location,
    nurseriesData,
    nurseriesTotalResults,
    nurseryOrder: {
      sortField: 'firstName',
      sortOrder: SORT_ORDER.ASC,
    },
    paginationUtils,
    selectors: childFundingsSelectors,
    setLocationQuery,
    statusOptions: CHILD_FUNDING_FILTER_OPTIONS,
  })

  const handleExportModelClick = (body, onSuccess, onFailed) => {
    const { criteria } = body

    const filterCriteria = childFundingsSelectors.getCriteria({
      ageRange: age?.ageRange,
      child: child?.value,
      dateRange: dateRange ? {
        after: dateRange[0],
        before: dateRange[1],
      } : undefined,
      fundingTypes: _.isArray(fundingTypes) ? _.map(fundingTypes, ({ value }) => value) : fundingTypes?.value,
      room: room?.value,
      status: status?.value,
    })

    childFundingsActions.exportReport({
      onFailed,
      onSuccess,
      params: {
        criteria: [
          ...criteria,
          ...filterCriteria,
        ],
      },
    })
  }

  const handleExportClick = () => {
    modalActions.show<ModalType.EXPORT_REPORT>(ModalType.EXPORT_REPORT, {
      onSendReport: handleExportModelClick,
      title: i18n.t('module:Finance:Reports:Funding:Export:title'),
      userLabel: i18n.t('module:Finance:Reports:Funding:Export:description'),
    })
  }

  const handleFundingLoopExportClick = () => {
    modalActions.show<ModalType.FUNDING_LOOP_EXPORT>(ModalType.FUNDING_LOOP_EXPORT)
  }

  const renderFilters = () => (
    <Toolbar bottomLine>
      <Toolbar.Group>
        <Toolbar.Item>
          <Form.Row
            label={i18n.t('global:Period')}
            verticalLabel
          >
            <DatePicker
              disabled={isFetching}
              value={dateRange}
              clearable
              range
              onChange={onDateChange}
            />
          </Form.Row>
        </Toolbar.Item>
        {!isChildContextRef.current && (
          <Toolbar.Item>
            <Form.Row
              label={i18n.t('module:Finance:Reports:Funding:Filters:Age:label')}
              verticalLabel
            >
              <Select
                disabled={isFetching}
                options={CHILD_AGES_OPTIONS}
                placeholder={i18n.t('global:AllAges')}
                value={age}
                onChange={onAgeChange}
              />
            </Form.Row>
          </Toolbar.Item>
        )}
        <Toolbar.Item>
          <Form.Row
            label={i18n.t('module:Finance:Reports:Funding:Filters:Status:label')}
            verticalLabel
          >
            <Select
              disabled={isFetching}
              options={CHILD_FUNDING_FILTER_OPTIONS}
              placeholder={i18n.t('module:Finance:Funding:allFundingPlaceholder')}
              value={status}
              onChange={onStatusChange}
            />
          </Form.Row>
        </Toolbar.Item>
        {!isChildContextRef.current && (
          <Toolbar.Item>
            <Form.Row
              label={i18n.t('module:Finance:Reports:Funding:Filters:Rooms:label')}
              verticalLabel
            >
              <InfiniteDropdowns.Rooms
                disabled={isFetching}
                placeholder={i18n.t('global:AllRooms')}
                value={room}
                onChange={onRoomChange}
              />
            </Form.Row>
          </Toolbar.Item>
        )}
        {!isChildContextRef.current && (
          <Toolbar.Item>
            <Form.Row
              label={i18n.t('module:Finance:Reports:Funding:Filters:Child:label')}
              verticalLabel
            >
              <InfiniteDropdowns.Children
                disabled={isFetching}
                value={child}
                onChange={onChildChange}
              />
            </Form.Row>
          </Toolbar.Item>
        )}
        {!isChildContextRef.current && (
          <Toolbar.Item>
            <Form.Row
              label={i18n.t('module:Finance:Reports:Funding:Filters:FundingTypes:label')}
              verticalLabel
            >
              <InfiniteDropdowns.NurseryFundingsV3
                criteria={[{
                  field: 'type',
                  value: [
                    NurseryFundingProductType.NURSERY_REGULAR_FUNDING,
                    NurseryFundingProductType.NURSERY_ONE_OFF_FUNDING,
                  ],
                }]}
                disabled={isFetching}
                placeholder={i18n.t('module:Finance:Reports:Funding:Filters:FundingTypes:placeholder')}
                value={fundingTypes}
                multi
                v2
                onChange={onFundingTypesChange}
              />
            </Form.Row>
          </Toolbar.Item>
        )}
        <Toolbar.Item>
          <Form.Row
            label={i18n.t('module:Finance:Reports:Funding:Filters:Allocation:label')}
            verticalLabel
          >
            <Select
              disabled={isFetching}
              options={FUNDING_ALLOCATION_OPTIONS}
              placeholder={i18n.t('module:Finance:Reports:Funding:Filters:Allocation:placeholder')}
              value={allocation}
              onChange={onAllocationChange}
            />
          </Form.Row>
        </Toolbar.Item>
      </Toolbar.Group>
    </Toolbar>
  )

  return (
    <ReportView.DetailPage
      description={isChildContextRef.current ? null : i18n.t('module:Finance:Reports:Funding:description')}
      errorMessages={errorMessages}
      filters={renderFilters()}
      hideExport={isChildContextRef.current}
      isFinanceV3Enabled={isFinanceV3Enabled}
      isPageLoading={isFetching && 1 === page}
      isTableLoading={isFetching}
      minWidth={1600}
      page={page}
      pageCount={pageCount}
      perPage={perPage}
      statisticsGap="60px"
      tableColumns={tableColumns}
      tableData={tableData}
      tableLayout="fixed"
      title={isChildContextRef
        ? i18n.t('module:Children:Child:Funding:List:title')
        : i18n.t('module:Finance:Reports:Funding:title')}
      totalResults={totalResults}
      onExportClick={handleExportClick}
      onFundingLoopExportClick={handleFundingLoopExportClick}
      onPageChange={onPageChange}
    />
  )
}

const enhance = compose(
  withRouter,
  withAppService,
  withChildFundingsService,
  withModalService,
  withPaginationUtils,
  withSecurityService,
  connector,
)

export default enhance(FundingV3Container)
